






































































































































































import { CarDealerSeller } from '@/api/interfaces/cardealerseller'
import { newCarDealerSeller } from '@/components/molecules/select-search/store/carDealerSeller'
import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'CreateCarDealerSellers',
  components: {
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
  },
  props: {
    dialogCarDealerSeller: {
      type: Boolean,
      required: true,
    },
    carDealer: {
      type: Object,
      required: false,
    },
    carDealerSellerEdit: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      isSubmiting: false,
      isEditingCarDealerSeller: false,
      carDealerSeller: newCarDealerSeller() as CarDealerSeller,
    }
  },
  watch: {
    carDealerSellerEdit: {
      handler (newVal) {
        if (newVal) {
          (this.carDealerSeller as CarDealerSeller) = { ...this.carDealerSellerEdit as CarDealerSeller }
          this.isEditingCarDealerSeller = true
        } else {
          this.carDealerSeller = newCarDealerSeller()
          this.isEditingCarDealerSeller = false
        }
      },
    },
  },
  created () {
    if (this.carDealerSellerEdit && this.carDealerSellerEdit['@id']) {
      this.carDealerSeller = { ...this.carDealerSellerEdit }
      this.isEditingCarDealerSeller = true
    }
  },
  methods: {
    ...mapActions('carDealerSellerList', {
      post: 'post',
      put: 'put',
    }),
    async onSubmit () {
      this.isSubmiting = true
      try {
        if (this.carDealerSeller && this.carDealerSeller['@id']) {
          // case update
          const carDealerSellerUpdated = await this.put(this.carDealerSeller)
          this.$emit('carDealerSellerUpdated', carDealerSellerUpdated)
        } else if (this.carDealer && this.carDealer['@id']) {
          // case create
          this.carDealerSeller.carDealer = this.carDealer['@id']
          this.$emit('carDealerSellerCreated', await this.post(this.carDealerSeller))
        }
        this.clear()
        this.$emit('close')
      } finally {
        this.isSubmiting = false
        this.isEditingCarDealerSeller = false
      }
    },
    clear () {
      this.carDealerSeller = newCarDealerSeller()
      const observer = this.$refs.observer as InstanceType<
      typeof ValidationObserver
    >
      observer.reset()
    },
  },
})
